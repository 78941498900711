import { lazy } from 'react';

export const AdminRoutes = [
  {
    path: 'dashboard',
    component: lazy(() => import('views/dashboard')),
  },
  {
    path: 'chat',
    component: lazy(() => import('views/chat/chat')),
  },
  {
    path: 'shops',
    component: lazy(() => import('views/shops/shops')),
  },
  // {
  //   path: 'point-delivery',
  //   component: lazy(() => import('views/point-delivery')),
  // },
  // {
  //   path: 'point-delivery/add',
  //   component: lazy(() => import('views/point-delivery/create')),
  // },
  // {
  //   path: 'point-delivery/:id',
  //   component: lazy(() => import('views/point-delivery/edit')),
  // },
  // {
  //   path: 'shop-tag',
  //   component: lazy(() => import('views/shop-tag')),
  // },
  // {
  //   path: 'shop-tag/add',
  //   component: lazy(() => import('views/shop-tag/tag-add')),
  // },
  // {
  //   path: 'shop-tag/edit',
  //   component: lazy(() => import('views/shop-tag/tag-edit')),
  // },
  {
    path: 'pos-system',
    component: lazy(() => import('views/pos-system/pos-system')),
  },
  {
    path: 'currencies',
    component: lazy(() => import('views/currencies/currencies')),
  },
  {
    path: 'wallets',
    component: lazy(() => import('views/wallet')),
  },
  {
    path: 'gallery',
    component: lazy(() => import('views/gallery')),
  },
  {
    path: 'subscriptions',
    component: lazy(() => import('views/subscriptions/subscriptions')),
  },
  {
    path: 'subscriptions/edit',
    component: lazy(() => import('views/subscriptions/subscriptions-edit')),
  },
  {
    path: 'orders',
    component: lazy(() => import('views/order/order')),
  },
  {
    path: 'order/refund',
    component: lazy(() => import('views/refund')),
  },
  {
    path: 'refund/details/:id',
    component: lazy(() => import('views/refund/refund-details')),
  },
  {
    path: 'refund-tag',
    component: lazy(() => import('views/refund-tag')),
  },
  {
    path: 'refund-tag/add',
    component: lazy(() => import('views/refund-tag/add')),
  },
  {
    path: 'refund-tag/:id',
    component: lazy(() => import('views/refund-tag/add')),
  },
  {
    path: 'banners',
    component: lazy(() => import('views/banners/banners')),
  },
  {
    path: 'notifications',
    component: lazy(() => import('views/notification')),
  },
  {
    path: 'my-shop',
    component: lazy(() => import('views/my-shop')),
  },
  {
    path: 'tickets',
    component: lazy(() => import('views/tickets')),
  },
  {
    path: 'blogs',
    component: lazy(() => import('views/blog')),
  },
  {
    path: 'transactions',
    component: lazy(() => import('views/transactions')),
  },
  {
    path: 'payout-requests',
    component: lazy(() => import('views/payout-requests')),
  },
  {
    path: 'reviews',
    component: lazy(() => import('views/reviews')),
  },
  {
    path: 'reviews/product',
    component: lazy(() => import('views/reviews/productReviews')),
  },
  {
    path: 'reviews/order',
    component: lazy(() => import('views/reviews/orderReviews')),
  },
  {
    path: 'settings',
    component: lazy(() => import('views/settings/settings')),
  },
  {
    path: 'settings/general',
    component: lazy(() => import('views/settings/generalSettings')),
  },
  {
    path: 'settings/languages',
    component: lazy(() => import('views/languages/languages')),
  },
  {
    path: 'settings/translations',
    component: lazy(() => import('views/translations')),
  },
  {
    path: 'settings/backup',
    component: lazy(() => import('views/backup')),
  },
  {
    path: 'settings/system-information',
    component: lazy(() => import('views/system-information')),
  },
  {
    path: 'settings/payments',
    component: lazy(() => import('views/payments')),
  },
  {
    path: 'settings/faqs',
    component: lazy(() => import('views/faq')),
  },
  {
    path: 'settings/sms-gateways',
    component: lazy(() => import('views/sms-gateways')),
  },
  {
    path: 'settings/terms',
    component: lazy(() => import('views/privacy/terms')),
  },
  {
    path: 'settings/policy',
    component: lazy(() => import('views/privacy/policy')),
  },
  {
    path: 'settings/update',
    component: lazy(() => import('views/update')),
  },
  {
    path: 'settings/firebase',
    component: lazy(() => import('views/settings/firebaseConfig')),
  },
  {
    path: 'settings/social',
    component: lazy(() => import('views/settings/socialSettings')),
  },
  {
    path: 'catalog',
    component: lazy(() => import('views/catalog/catalog')),
  },
  {
    path: 'deliveryzone',
    component: lazy(() => import('views/deliveryzone')),
  },
  {
    path: 'deliveryzone/country',
    component: lazy(() => import('views/deliveryzone/country')),
  },
  {
    path: 'deliveryzone/region',
    component: lazy(() => import('views/deliveryzone/region')),
  },
  {
    path: 'deliveryzone/city',
    component: lazy(() => import('views/deliveryzone/city')),
  },
  {
    path: 'catalog/products',
    component: lazy(() => import('views/products/products')),
  },
  {
    path: 'catalog/product/import',
    component: lazy(() => import('views/products/product-import')),
  },
  {
    path: 'catalog/extras',
    component: lazy(() => import('views/products/Extras/extras-page')),
  },
  {
    path: 'catalog/categories',
    component: lazy(() => import('views/categories')),
  },
  {
    path: 'catalog/categories/import',
    component: lazy(() => import('views/categories/category-import')),
  },
  {
    path: 'catalog/brands',
    component: lazy(() => import('views/brands/brands')),
  },
  {
    path: 'catalog/brands/import',
    component: lazy(() => import('views/brands/brand-import')),
  },
  {
    path: 'catalog/units',
    component: lazy(() => import('views/units')),
  },
  {
    path: 'delivery',
    component: lazy(() => import('views/delivery/delivery-list')),
  },
  {
    path: 'delivery/list',
    component: lazy(() => import('views/delivery/delivery')),
  },
  {
    path: 'delivery/deliveryman',
    component: lazy(() => import('views/delivery/deliveryman')),
  },
  {
    path: 'deliveryman/orders',
    component: lazy(() => import('views/deliveryman-orders/order')),
  },
  {
    path: 'deliveryman/order/details/:id',
    component: lazy(() => import('views/deliveryman-orders/order-details')),
  },
  {
    path: 'cashback',
    component: lazy(() => import('views/cashback')),
  },
  {
    path: 'users',
    component: lazy(() => import('views/user')),
  },
  {
    path: 'users/user',
    component: lazy(() => import('views/user/user')),
  },
  {
    path: 'users/admin',
    component: lazy(() => import('views/user/admin')),
  },
  {
    path: 'users/role',
    component: lazy(() => import('views/user/role-list')),
  },
  {
    path: 'shop/add',
    component: lazy(() => import('views/shops/shops-add')),
  },
  {
    path: 'brand/add',
    component: lazy(() => import('views/brands/brands-add')),
  },
  {
    path: 'category/add',
    component: lazy(() => import('views/categories/category-add')),
  },
  {
    path: 'product/add',
    component: lazy(() => import('views/products/products-add')),
  },
  {
    path: 'language/add',
    component: lazy(() => import('views/languages/language-add')),
  },
  {
    path: 'currency/add',
    component: lazy(() => import('views/currencies/currencies-add')),
  },
  {
    path: 'order/add',
    component: lazy(() => import('views/order/orders-add')),
  },
  {
    path: 'deliveries/add',
    component: lazy(() => import('views/delivery/delivery-add')),
  },
  {
    path: 'user/add',
    component: lazy(() => import('views/user/user-add')),
  },
  {
    path: 'banner/add',
    component: lazy(() => import('views/banners/banner-add')),
  },
  {
    path: 'notification/add',
    component: lazy(() => import('views/notification/notification-add')),
  },
  {
    path: 'coupons',
    component: lazy(() => import('views/coupons/Coupon')),
  },
  {
    path: 'coupon/add',
    component: lazy(() => import('views/coupons/CouponAdd')),
  },
  {
    path: 'ticket/add',
    component: lazy(() => import('views/tickets/ticketAdd')),
  },
  {
    path: 'unit/add',
    component: lazy(() => import('views/units/unit-add')),
  },
  {
    path: 'discount/add',
    component: lazy(() => import('views/seller-views/discounts/discount-add')),
  },
  {
    path: 'blog/add',
    component: lazy(() => import('views/blog/blog-add')),
  },
  {
    path: 'faq/add',
    component: lazy(() => import('views/faq/faq-add')),
  },
  // {
  //   path: 'catalog/groups',
  //   component: lazy(() => import('views/groups/groups')),
  // },
  // {
  //   path: 'groups/add',
  //   component: lazy(() => import('views/groups/group-add')),
  // },
  // {
  //   path: 'group/:id',
  //   component: lazy(() => import('views/groups/group-edit')),
  // },
  {
    path: 'language/:id',
    component: lazy(() => import('views/languages/language-add')),
  },
  {
    path: 'category/:uuid',
    component: lazy(() => import('views/categories/category-edit')),
  },
  {
    path: 'currency/:id',
    component: lazy(() => import('views/currencies/currency-edit')),
  },
  {
    path: 'brand/:id',
    component: lazy(() => import('views/brands/brands-edit')),
  },
  {
    path: 'shop/:uuid',
    component: lazy(() => import('views/shops/shops-add')),
  },
  {
    path: 'product/:uuid',
    component: lazy(() => import('views/products/product-edit')),
  },
  {
    path: 'order/details/:id',
    component: lazy(() => import('views/order/order-details')),
  },
  {
    path: 'delivery/:id',
    component: lazy(() => import('views/delivery/delivery-add')),
  },
  {
    path: 'order/:id',
    component: lazy(() => import('views/order/order-edit')),
  },
  {
    path: 'user/:uuid',
    component: lazy(() => import('views/user/user-edit')),
  },
  {
    path: 'banner/:id',
    component: lazy(() => import('views/banners/banner-edit')),
  },
  {
    path: 'notification/:uuid',
    component: lazy(() => import('views/notification/notification-edit')),
  },
  {
    path: 'coupon/:id',
    component: lazy(() => import('views/coupons/CouponAdd')),
  },
  {
    path: 'unit/:id',
    component: lazy(() => import('views/units/unit-edit')),
  },
  {
    path: 'discount/:id',
    component: lazy(() => import('views/seller-views/discounts/discount-edit')),
  },
  {
    path: 'blog/:uuid',
    component: lazy(() => import('views/blog/blog-edit')),
  },
  {
    path: 'faq/:uuid',
    component: lazy(() => import('views/faq/faq-edit')),
  },
  {
    path: 'gallery/:type',
    component: lazy(() => import('views/gallery/gallery-languages')),
  },
  {
    path: 'report',
    component: lazy(() => import('views/report')),
  },
  {
    path: 'report/overview',
    component: lazy(() => import('views/report-overview')),
  },
  {
    path: 'report/products',
    component: lazy(() => import('views/report-products')),
  },
  {
    path: 'report/revenue',
    component: lazy(() => import('views/report-revenue')),
  },
  {
    path: 'report/orders',
    component: lazy(() => import('views/report-orders')),
  },
  {
    path: 'report/variation',
    component: lazy(() => import('views/report-variation')),
  },
  {
    path: 'report/categories',
    component: lazy(() => import('views/report-categories')),
  },

  {
    path: 'report/stock',
    component: lazy(() => import('views/report-stock')),
  },
  {
    path: 'report/shops',
    component: lazy(() => import('views/report-shop')),
  },
];
